import Auth0Lock from "auth0-lock";
import { config } from "../settings";

export default class Auth0Util {
  showLock(container) {
    const lock = new Auth0Lock(config.clientID, config.domain, {
      container,
      closable: false,
      auth: {
        responseType: "token id_token",
        redirectUrl: this.getBaseUrl() + "/callback",
        params: { scope: "openid profile email" }
      },
      theme: {
        primaryColor: "#3CABAD",
        logo: "https://sharefull.com/wp-content/themes/sharefull/img/it_1.png"
      },
      languageDictionary: {
        title: "シェアフル"
      },
      language: "ja",
      allowSignUp: false
    });

    lock.show({ initialScreen: "signUp" });

    lock.on("authenticated", async authResult => {
      window.location.href = `${this.getBaseUrl()}/callback?access_token=${
        authResult.accessToken
      }&expires_in=${authResult.expiresIn}`;
    });
  }

  getBaseUrl() {
    return `${window.location.protocol}//${window.location.host}`;
  }
}
