import React from "react";
import Divider from "./components/Divider";

export const getTomorrowYYYYMMDDWithHyphon = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const y = date.getFullYear();
  const m = ("0" + (date.getMonth() + 1)).slice(-2);
  const d = ("0" + date.getDate()).slice(-2);
  return `${y}-${m}-${d}`;
};

export const getDoubleDigits = num => {
  num += "";
  if (num.length === 1) {
    num = "0" + num;
  }
  return num;
};

export const fmtOrder = ({
  order,
  shugyobi,
  shugyoKaishiJikan,
  shugyoShuryoJikan,
  usePayroll,
  kyukeiKaishiJikan1,
  kyukeiShuryoJikan1,
  kyukeiMinutes,
  boshuNinzu,
  kijunTanka,
  title
}) => {
  // 前処理(オーダーの型の整形)
  delete order.shugyobis;
  delete order.shugyobi;
  delete order.kyukeiMinutes;
  delete order.kyukeiKaishiJikan1;
  delete order.kyukeiShuryoJikan1;
  order.orderCampaignId = 0;
  order.access1 = {
    moyoriEki: order.moyoriEki1,
    kotsuShudan: order.kotsuShudan1,
    shoyoJikanMinutes: parseInt(order.shoyoJikanMinutes1),
    moyoriEkiStationGroupCode: parseInt(order.moyoriEkiStationGroupCode1),
    sogeiRiyo: order.sogeiRiyo1,
    sogeiJoho: order.sogeiJoho1
  };
  order.agentApproved = true;
  // ここでオーダーを書き換える
  order.shugyobis = [`${shugyobi}T00:00:00+09:00`]; // 日付 2018-08-01の形式
  order.shugyoKaishiJikan = `${shugyobi}T${shugyoKaishiJikan}:00+09:00`; // 開始時間 12:00の形式
  order.shugyoShuryoJikan = `${shugyobi}T${shugyoShuryoJikan}:00+09:00`; // 終了時間 13:00の形式

  if (usePayroll) {
    order.kyukeiKaishiJikan1 = kyukeiKaishiJikan1
      ? `${shugyobi}T${kyukeiKaishiJikan1}:00+09:00`
      : null; // 休憩開始時間 12:00の形式
    order.kyukeiShuryoJikan1 = kyukeiShuryoJikan1
      ? `${shugyobi}T${kyukeiShuryoJikan1}:00+09:00`
      : null; // 休憩終了時間 13:00の形式
  } else {
    order.kyukeiMinutes = order.kyukeiMinutes ? +kyukeiMinutes : null; // 休憩時間
  }

  order.boshuNinzu = +boshuNinzu; // 募集人数
  order.kijunTanka = +kijunTanka; // 時給
  order.title = title; // タイトル
  return order;
};

export const fmtTemplate = t => ({
  id: t.id,
  shugyobi: t.shugyobi,
  shugyoKaishiJikan: t.shugyoKaishiJikan,
  shugyoShuryoJikan: t.shugyoShuryoJikan,
  kyukeiMinutes: t.kyukeiMinutes,
  kyukeiKaishiJikan1: t.kyukeiKaishiJikan1,
  kyukeiShuryoJikan1: t.kyukeiShuryoJikan1,
  boshuNinzu: t.boshuNinzu,
  kijunTanka: t.kijunTanka,
  title: t.title,
  tantoshaName: t.tantoshaName,
  shugyoBasho: t.shugyoBasho
});

export const fmtTime = date => {
  const d = new Date(date);
  return `${getDoubleDigits(d.getHours())}:${getDoubleDigits(d.getMinutes())}`;
};

export const templateListItemGen = ({ data, footer }) => {
  const date = new Date(data.shugyobi);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return (
    <li key={data.id}>
      <div>就業日: {`${year}/${month}/${day}`}</div>
      <div>求人名: {`${data.title}`}</div>
      <div>担当者: {`${data.tantoshaName}`}</div>
      <div>就業場所: {`${data.shugyoBasho}`}</div>
      <div>
        URL:
        <small>{` https://client.sharefull.com/orders/${data.id}`}</small>
      </div>
      {footer}
      <Divider />
    </li>
  );
};

export const getInitialInput = () =>
  JSON.parse(
    JSON.stringify({
      selectedOrderTemplateId: "",
      shugyobi: getTomorrowYYYYMMDDWithHyphon(),
      shugyoKaishiJikan: "",
      shugyoShuryoJikan: "",
      kyukeiMinutes: "",
      kyukeiKaishiJikan1: "",
      kyukeiShuryoJikan1: "",
      boshuNinzu: "",
      kijunTanka: "",
      title: ""
    })
  );
