export default `query Orders($includeClosed: Boolean = false, $from: String = "", $to: String = "") {
  orders(limit: 10000, includeClosed: $includeClosed, from: $from, to: $to) {
    nodes {
      agentApproved
      assignmentAcceptedCount
      assignmentCount
      assignmentDoneCount
      boshuNinzu
      shugyoBasho
      id
      uniqueID
      kijunTanka
      orderGroupId
      shiharaiKeitai
      shugyobi
      shugyoKaishiJikan
      shugyoShuryoJikan
      statusCode
      tantoshaName
      kyukeiKaishiJikan1
      kyukeiKaishiJikan2
      kyukeiKaishiJikan3
      kyukeiMinutes
      kyukeiShuryoJikan1
      kyukeiShuryoJikan2
      kyukeiShuryoJikan3
      title
      groupedOrder {
        orderId
        shugyobi
      }
      jobType {
        name1
        code1
      }
      clientAccount {
        id
      }
    }
  }
}
`;
