import React from "react";

export default ({ label, value, type = "text", min, disabled, onChange }) => (
  <div style={{ display: "flex" }}>
    <label style={{ width: "30%", textAlign: "left" }}>{label}</label>
    <input
      disabled={disabled}
      style={{ width: "70%" }}
      value={value}
      onChange={onChange}
      type={type}
      min={min}
    />
  </div>
);
