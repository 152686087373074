import React from "react";
import logo from "../images/logo_w.png";
import icon from "../images/icon_w.png";

export default ({ onClick, disabled }) => (
  <div
    style={{
      marginTop: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      width: 300,
      height: 100,
      borderRadius: 60,
      border: "2px solid #eee",
      borderBottom: "10px solid rgb(218, 218, 218)",
      background: "#5bccce",
      padding: "0.1rem",
      boxShadow: "1px 2px 3px"
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "70%"
      }}
    >
      <img src={icon} width="30" alt="" />
      <img src={logo} width="100" alt="" />
    </div>
    <button
      type="submit"
      style={{
        width: "30%",
        height: 80,
        background: "#fff",
        borderRadius: 50,
        boxShadow: "3px 0px 3px 3px rgba(0, 0, 0, 0.08) inset"
      }}
      onClick={onClick}
      disabled={disabled}
    >
      作成
    </button>
  </div>
);
