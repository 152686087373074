import Auth0Lock from "auth0-lock";
import auth0js from "auth0-js";
import { config } from "../settings";

const auth0 = new auth0js.WebAuth(config);

const lock = new Auth0Lock(config.clientID, config.domain, {
  theme: {
    primaryColor: "#3CABAD",
    logo: "https://sharefull.com/wp-content/themes/sharefull/img/it_1.png"
  },
  languageDictionary: {
    title: "シェアフル"
  },
  language: "ja",
  allowSignUp: false,
  container: "show-auth",
  closable: false,
  auth: {
    responseType: "token id_token",
    redirectUrl: `${window.location.protocol}//${window.location.host}/callback`,
    params: {
      scope: "openid profile email"
    }
  }
});

const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

let refresh = false;

const refreshInterval = async () => {
  await sleep(10000);
  if (!refresh) {
    return true;
  }

  await sleep(10000);
  if (!refresh) {
    return true;
  }

  await sleep(10000);
  if (!refresh) {
    return true;
  }

  return false;
};

export default class Auth {
  getIdTokenNoRefresh() {
    if (!this.signedIn()) {
      return false;
    }
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error("Not found token");
    }

    return accessToken;
  }

  async getIdToken() {
    if (!this.signedIn()) {
      return false;
    }

    const expiresIn = localStorage.getItem("expiresIn");

    const unixTimeStamp = Math.floor(Date.now() / 1000);

    if (Number(expiresIn) < unixTimeStamp) {
      refresh = true;
      auth0.renewAuth(
        {
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          clientID: config.clientID,
          audience: `https://${config.domain}/userinfo`,
          usePostMessage: true
        },
        () => {}
      );

      let result = await refreshInterval();
      if (!result) {
        alert("アクセスに失敗しました");
        return false;
      }
    }

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error("Not found token");
    }

    return accessToken;
  }

  signedIn() {
    return !!localStorage.getItem("accessToken");
  }

  async setToken(accessToken, expiresIn) {
    await localStorage.setItem("accessToken", accessToken);
    const unixTimeStamp = Math.floor(Date.now() / 1000);
    await localStorage.setItem("expiresIn", unixTimeStamp + expiresIn);
  }

  async signOut() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiresIn");
    await lock.logout({
      returnTo: `${window.location.protocol}//${window.location.host}/login`
    });
  }
}

const auth = new Auth();

// トークンのリフレッシュ方法
// https://community.auth0.com/t/renewal-of-tokens-in-a-spa/6197
//
// 注意事項: https://github.com/auth0/auth0.js/issues/506

window.addEventListener(
  "message",
  async event => {
    if (localStorage.getItem("TESTCAFE")) {
      return;
    }

    if (!event.data || event.data.type !== "authorization_response") {
      return;
    }

    if (event.data.response.error === "login_required") {
      const signedIn = auth.signedIn();

      if (signedIn) {
        alert("ログインのセッション期間期限が過ぎたのでログアウトしました。");
        // リフレッシュできないトークンの場合はログアウトさせる
        await auth.signOut();
      }
      return;
    }

    await auth.setToken(
      event.data.response.access_token,
      event.data.response.expires_in
    );

    refresh = false;
  },
  false
);
