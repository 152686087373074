export default `query ClientAccountMe {
  clientAccountMe {
    appliedOrders {
      appliedCount
      order {
        id
        shugyobi
        shugyoKaishiJikan
        shugyoShuryoJikan
      }
    }
    id
    isOwner
    departmentName
    name
    nameKana
    orderUnregistered
    phone
    postcode
    prefcode
    address
    email
    limitedFeatureIds
    agentCompany {
      id
      name
    }
    clientCompany {
      id
      name
    }
    clientSubAccount {
      name
      email
    }
    roleId
  }
  clientAccountShokaiSetting {
    acceptTermsStatus
    clientAccountId
    clientBillingId
    clientBilling {
      id
      address
      companyName
      departmentName
      phone
      postcode
      kessaiHoho
      seikyuShimebi
      seikyushoHakkoKeitai
      tantoshaName
    }
    id
    tesuryo
    usageStatus
  }
  clientCompanyPayrollSetting {
    id
    kyuyoKeisanUsageStatus
    kintaiShoninPattern
  }
}
`;
