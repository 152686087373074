export default `query Order($id: Int) {
    order(id: $id) {
      address
      agentApproved
      agentApproved
      boshuNinzu
      chuiJiko
      chuiJikoCheck
      clientAccountId
      clientCompanyOrderMessageID
      fukuso
      grouped
      gyomuShosai
      id
      uniqueID
      jikangaiRodo
      jikangaiRodoMeyasuMinutes
      jobTypeCode
      jobTypeKeiken
      kijunTanka
      kojoShosai
      kotsuShudan1
      kotsuShudan2
      kotsuShudan3
      kyukeiKaishiJikan1
      kyukeiKaishiJikan2
      kyukeiKaishiJikan3
      kyukeiMinutes
      kyukeiShuryoJikan1
      kyukeiShuryoJikan2
      kyukeiShuryoJikan3
      kyuyoJokenId
      kyuyoShiharaiHoho
      kyuyoShiharaiHohoShosai
      latitude
      longitude
      machiawase
      mochimono
      moyoriEki1
      moyoriEki2
      moyoriEki3
      orderCampaignId
      postcode
      prefcode
      question1
      question1Required
      question2
      question2Required
      question3
      question3Required
      shiharaiKeitai
      shokugyoBunruiCode
      shoyoJikanMinutes1
      shoyoJikanMinutes2
      shoyoJikanMinutes3
      shugyoBasho
      shugyobi
      shugyoKaishiJikan
      shugyoShuryoJikan
      sogeiJoho1
      sogeiJoho2
      sogeiJoho3
      sogeiRiyo1
      sogeiRiyo2
      sogeiRiyo3
      sokukyuFlag
      souteiNikkyu
      statusCode
      tantoshaEmail
      tantoshaName
      tantoshaPhone
      taskCode
      teateShosai
      title
      tsukinKotsuhi
      tsukinKotsuhiJogen
      uketsukeNengappi
      updatedAccountId
      updatedAccountType
      updatedDate
      jobType {
        code1
        name1
        jobType2 {
          code
          name2
        }
      }
      groupedOrder {
        orderId
        shugyobi
      }
      clientAccount {
        id
        clientCompany {
          id
          name
        }
      }
  
      kyuyoJoken {
        id
        kyuyoShimebi
        shiharaiSaito
        shiharaibi
        donichinoBaainoTaioHohoShiharaibi
        hoteiShinyaJikantai
        hoteiShinyaWarimashiritsu
        shoteiShinyaStartedTime
        shoteiShinyaFinishedTime
        shoteiShinyaWarimashiritsu
        hoteiJikangaiWarimashiritsu
        shoteiJikangaiWarimashiritsu
        hoteiKyujitsuWarimashiritsu
        shoteiKyujitsuWarimashiritsu
        shoteiRodoJikan
        shoteiKyujitsuYobi
        kintaiJikanMarumeTani
      }
      tasks {
        code
        categoryCode
        categoryName
        categoryOrder
        contentCode
        contentName
        contentOrder
        scoreType
      }
      orderQuestion {
        id
        question1
        question2
        question3
        question1Type
        question2Type
        question3Type
        question1Options
        question2Options
        question3Options
        question1Required
        question2Required
        question3Required
      }
    }
  }`;
