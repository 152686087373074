import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

window.liff
  .init({ liffId: "1653726723-OvlgDkdn" })
  .then(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
  })
  .catch(err => {
    alert("エラーが発生しました: " + err);
  });
