import React, { useEffect, useState } from "react";
import Auth0Util from "./libs/Auth0Util";
import Auth from "./libs/auth0";
import DashButton from "./components/DashButton";
import ActionAnimations from "./components/ActionAnimations";
import Divider from "./components/Divider";
import InputSet from "./components/InputSet";
import { STATUS } from "./constants";
import orderQuery from "./queries/order";
import ordersQuery from "./queries/orders";
import clientAccountMeQuery from "./queries/clientAccountMe";
import {
  getTomorrowYYYYMMDDWithHyphon,
  getDoubleDigits,
  fmtOrder,
  fmtTemplate,
  fmtTime,
  templateListItemGen,
  getInitialInput
} from "./util";

const auth = new Auth();

const ENDPOINT =
  "https://qa-dot-tiger-backend-dot-animals-tiger-review.appspot.com";

export default () => {
  const [disabled, setDisabled] = useState(true);
  const [status, setStatus] = useState(STATUS.INITIAL);
  const [bearerToken, setBearerToken] = useState("");
  const [usePayroll, setUsePayroll] = useState(false);
  const [isEditTemplateMode, setIsEditTemplateMode] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateCandidates, setTemplateCandidates] = useState([]);
  const [month4TemplateChoice, setMonth4TemplateChoice] = useState("1");
  const [input, setInput] = useState(getInitialInput());

  useEffect(() => {
    const token = auth.getIdTokenNoRefresh();
    if (token) {
      const _input = localStorage.getItem("input");
      if (_input) {
        const __input = JSON.parse(_input);
        __input.shugyobi = getTomorrowYYYYMMDDWithHyphon();
        setInput(__input);
      }
      const _templates = localStorage.getItem("templates");
      if (_templates && JSON.parse(_templates).length > 0) {
        const __templates = JSON.parse(_templates);
        setTemplates(__templates);
      } else {
        getTemplateFromServer(1, token).then(res => {
          setTemplateCandidates(res.data.orders.nodes.map(fmtTemplate));
        });
      }
      setBearerToken(token);
      fetch(`${ENDPOINT}/client/v1/graphql`, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`
        },
        method: "post",
        body: JSON.stringify({
          query: clientAccountMeQuery
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.data) {
            const clientAccounId = localStorage.getItem("clientAccounId");
            if (clientAccounId !== res.data.clientAccountMe.id) {
              localStorage.removeItem("templates");
              localStorage.removeItem("input");
              setInput(getInitialInput());
              setTemplates([]);
            }
            localStorage.setItem("clientAccounId", res.data.clientAccountMe.id);
            setUsePayroll(
              res.data.clientCompanyPayrollSetting.kyuyoKeisanUsageStatus === 4
            );
          }
        })
        .then(() => {
          setDisabled(false);
        });
    } else {
      new Auth0Util().showLock("show-auth");
    }
  }, []);

  const getTemplateFromServer = (monthAgo = 1, token = "") => {
    const toDate = new Date();
    const toYear = toDate.getFullYear();
    const toMonth = getDoubleDigits(toDate.getMonth() + 1);
    const toDay = getDoubleDigits(toDate.getDate());

    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - Number(monthAgo));
    const fromYear = fromDate.getFullYear();
    const fromMonth = getDoubleDigits(fromDate.getMonth() + 1);
    const fromDay = getDoubleDigits(fromDate.getDate());
    return fetch(`${ENDPOINT}/client/v1/graphql`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${token ? token : bearerToken}`
      },
      method: "post",
      body: JSON.stringify({
        query: ordersQuery,
        variables: {
          includeClosed: true,
          from: `${fromYear}-${fromMonth}-${fromDay}`,
          to: `${toYear}-${toMonth}-${toDay}`
        }
      })
    }).then(res => res.json());
  };

  const signOut = async () => {
    await auth.signOut();
  };

  const getOrder = id =>
    fetch(`${ENDPOINT}/client/v1/graphql`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${bearerToken}`
      },
      method: "post",
      body: JSON.stringify({ query: orderQuery, variables: { id } })
    }).then(res => res.json());

  const registerOrder = async () => {
    const {
      selectedOrderTemplateId,
      shugyobi,
      shugyoKaishiJikan,
      shugyoShuryoJikan,
      kyukeiKaishiJikan1,
      kyukeiShuryoJikan1,
      kyukeiMinutes,
      boshuNinzu,
      kijunTanka,
      title
    } = input;
    if (!selectedOrderTemplateId) return;
    setDisabled(true);
    setStatus(STATUS.LOADING);
    const order = await getOrder(+input.selectedOrderTemplateId).then(
      res => res.data.order
    );
    const res = await fetch(`${ENDPOINT}/client/v1/rest/RegisterOrder`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${bearerToken}`
      },
      method: "post",
      body: JSON.stringify({
        order: fmtOrder({
          order,
          shugyobi,
          shugyoKaishiJikan,
          shugyoShuryoJikan,
          usePayroll,
          kyukeiKaishiJikan1,
          kyukeiShuryoJikan1,
          kyukeiMinutes,
          boshuNinzu,
          kijunTanka,
          title
        }),
        confirmedDuplication: true,
        skipAgentApprove: true
      })
    });
    const isOk = Boolean(res.ok);
    setStatus(isOk ? STATUS.SUCCESS : STATUS.FAIL);
    setDisabled(false);
  };

  const onChangeInput = (k, v) => {
    const inputObj = { [k]: v ? v : "" };
    if (k === "selectedOrderTemplateId") {
      const slctOrdTmplt = templates.find(t => t.id === v);
      if (slctOrdTmplt) {
        inputObj.shugyoKaishiJikan = fmtTime(slctOrdTmplt.shugyoKaishiJikan);
        inputObj.shugyoShuryoJikan = fmtTime(slctOrdTmplt.shugyoShuryoJikan);
        inputObj.kyukeiMinutes = slctOrdTmplt.kyukeiMinutes;
        inputObj.kyukeiKaishiJikan1 = slctOrdTmplt.kyukeiKaishiJikan1
          ? fmtTime(slctOrdTmplt.kyukeiKaishiJikan1)
          : "";
        inputObj.kyukeiShuryoJikan1 = slctOrdTmplt.kyukeiShuryoJikan1
          ? fmtTime(slctOrdTmplt.kyukeiShuryoJikan1)
          : "";
        inputObj.boshuNinzu = slctOrdTmplt.boshuNinzu;
        inputObj.kijunTanka = slctOrdTmplt.kijunTanka;
        inputObj.title = slctOrdTmplt.title;
      }
    }
    const newInput = JSON.parse(JSON.stringify(Object.assign(input, inputObj)));
    localStorage.setItem("input", JSON.stringify(newInput));
    setInput(newInput);
  };

  const onCompleteAnimation = status => {
    if (STATUS.SUCCESS === status) {
      const result = window.confirm(
        `求人が作成できました！
入力を続けますか？`
      );
      if (!result) window.liff.closeWindow();
    } else if (STATUS.FAIL === status) {
      alert(
        `求人が作成できませんでした。
入力内容を確かめ、もう一度入力をお願いいたします。
それでもうまくいかない場合はシェアフルサポートまでお問い合わせください。`
      );
    }
    setStatus(STATUS.INITIAL);
  };

  const isSubmitButtonDisabled = () => {
    const target = JSON.parse(JSON.stringify(input));
    // 休憩時間はバリデーションしない
    delete target.kyukeiMinutes;
    delete target.kyukeiKaishiJikan1;
    delete target.kyukeiShuryoJikan1;
    return !Object.values(target).every(v => !!v) || disabled;
  };

  const openEditTemplate = () => {
    setIsEditTemplateMode(true);
    getTemplateFromServer().then(res => {
      setTemplateCandidates(res.data.orders.nodes.map(fmtTemplate));
    });
  };

  const closeEditTemplate = () => {
    setIsEditTemplateMode(false);
  };

  const changeMonth4TemplateChoise = e => {
    setMonth4TemplateChoice(e.target.value);
    getTemplateFromServer(Number(e.target.value)).then(res => {
      setTemplateCandidates(res.data.orders.nodes.map(fmtTemplate));
    });
  };

  const getTemplateCandidateListItem = data => {
    const isAdded = templates.map(t => t.id).includes(data.id);
    return templateListItemGen({
      data,
      footer: (
        <button disabled={isAdded} onClick={() => addTemplate(data)}>
          テンプレートに登録{isAdded && "済み"}
        </button>
      )
    });
  };

  const getTemplateListItem = data =>
    templateListItemGen({
      data,
      footer: (
        <>
          <button onClick={() => editTemplate(data)}>編集</button>
          <button onClick={() => removeTemplate(data)}>削除</button>
        </>
      )
    });

  const addTemplate = data => {
    const title = window.prompt(
      "求人のタイトルを編集することができます\nデフォルトで求人のタイトルが入っています。",
      data.title
    );
    if (!title) {
      return alert("名前を入力してください。");
    }
    const newTemplates = templates.concat([
      Object.assign(JSON.parse(JSON.stringify(data)), { title })
    ]);
    setTemplates(newTemplates);

    setInput(
      Object.assign(JSON.parse(JSON.stringify(data)), {
        title,
        shugyoKaishiJikan: fmtTime(data.shugyoKaishiJikan),
        shugyoShuryoJikan: fmtTime(data.shugyoShuryoJikan),
        kyukeiKaishiJikan1: data.kyukeiKaishiJikan1
          ? fmtTime(data.kyukeiKaishiJikan1)
          : "",
        kyukeiShuryoJikan1: data.kyukeiShuryoJikan1
          ? fmtTime(data.kyukeiShuryoJikan1)
          : "",
        shugyobi: getTomorrowYYYYMMDDWithHyphon(),
        selectedOrderTemplateId: data.id
      })
    );
    localStorage.setItem("templates", JSON.stringify(newTemplates));
  };

  const editTemplate = data => {
    const _templates = JSON.parse(JSON.stringify(templates));
    const tgt = _templates.find(t => t.id === data.id);
    if (!tgt) {
      alert("エラーが発生しました。そのテンプレートは存在しません。");
      removeTemplate(data);
      return;
    }
    const newTitle = window.prompt("求人のタイトルを編集できます", data.title);
    if (!newTitle) return;
    tgt.title = newTitle;
    setTemplates(_templates);
  };

  const removeTemplate = data => {
    if (!window.confirm("削除します。よろしいですか？")) return;
    const newTemplates = templates.filter(t => t.id !== data.id);
    setTemplates(newTemplates);
    localStorage.setItem("templates", JSON.stringify(newTemplates));
    if (input.selectedOrderTemplateId === data.id) {
      setInput(getInitialInput());
      localStorage.removeItem("input");
    }
  };

  const isEmptyTemplate = templates.length === 0;

  return (
    <div style={{ textAlign: "center", width: 300, margin: "0 auto" }}>
      <h3>シェアフル Dash Button</h3>
      {bearerToken ? (
        isEditTemplateMode || isEmptyTemplate ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button disabled={isEmptyTemplate} onClick={closeEditTemplate}>
                x
              </button>
              <div>テンプレを編集&amp;登録</div>
            </div>
            <Divider />
            <div>
              <p>現在のテンプレートを編集</p>
              <ul style={{ fontSize: "small", textAlign: "left" }}>
                {isEmptyTemplate ? (
                  <p style={{ color: "#CA475C" }}>
                    テンプレートが登録されていません
                    <br />
                    新しくテンプレートを登録してください。
                  </p>
                ) : (
                  templates.map(getTemplateListItem)
                )}
              </ul>
            </div>
            <Divider />
            <div>
              <p>新しくテンプレートを登録</p>
              <small>
                本日から
                <select
                  value={month4TemplateChoice}
                  onChange={changeMonth4TemplateChoise}
                >
                  {Array.from({ length: 12 })
                    .map((_, i) => String(i + 1))
                    .map(i => (
                      <option key={i} value={i}>
                        {i}ヶ月前
                      </option>
                    ))}
                </select>
                までを表示
              </small>
              <Divider />
              {templateCandidates.length === 0 ? (
                <div>
                  <p>読み込み中</p>
                  <small style={{ color: "#9FA0A0" }}>
                    しばらくたっても表示されない場合は{month4TemplateChoice}
                    ヶ月前までに求人を作成していない場合があります。
                  </small>
                </div>
              ) : (
                <ul style={{ fontSize: "small", textAlign: "left" }}>
                  {templateCandidates.map(getTemplateCandidateListItem)}
                </ul>
              )}
            </div>
          </>
        ) : (
          <>
            <ActionAnimations
              status={status}
              onComplete={onCompleteAnimation}
            />
            <div style={{ display: "flex" }}>
              <label style={{ width: "30%", textAlign: "left" }}>
                テンプレ
              </label>
              <select
                style={{ width: "70%" }}
                value={input.selectedOrderTemplateId}
                onChange={e => {
                  onChangeInput("selectedOrderTemplateId", e.target.value);
                }}
              >
                <option value={""} disabled>
                  選択してください
                </option>
                {templates.map(t => (
                  <option key={t.id} value={t.id}>
                    {t.title}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <button onClick={openEditTemplate}>
                テンプレを編集&amp;登録
              </button>
            </div>
            <Divider />
            <InputSet
              label="日付"
              disabled={disabled}
              type="date"
              min={getTomorrowYYYYMMDDWithHyphon()}
              value={input.shugyobi}
              onChange={e => onChangeInput("shugyobi", e.target.value)}
            />
            <Divider />
            <div style={{ fontSize: "small", marginTop: "1rem" }}>
              <span style={{ width: "16%" }}>
                {usePayroll ? "業務" : ""}開始
              </span>
              <input
                disabled={disabled}
                style={{ width: "22%" }}
                min={1}
                value={input.shugyoKaishiJikan}
                onChange={e =>
                  onChangeInput("shugyoKaishiJikan", e.target.value)
                }
                type="time"
              />
              <span style={{ width: "16%" }}>
                {usePayroll ? "業務" : ""}終了
              </span>
              <input
                disabled={disabled}
                style={{ width: "22%" }}
                min={1}
                value={input.shugyoShuryoJikan}
                onChange={e =>
                  onChangeInput("shugyoShuryoJikan", e.target.value)
                }
                type="time"
              />
              {usePayroll ? (
                <div>
                  <span style={{ width: "16%" }}>休憩開始</span>
                  <input
                    disabled={disabled}
                    style={{ width: "22%" }}
                    min={1}
                    value={input.kyukeiKaishiJikan1}
                    onChange={e =>
                      onChangeInput("kyukeiKaishiJikan1", e.target.value)
                    }
                    type="time"
                  />
                  <span style={{ width: "16%" }}>休憩終了</span>
                  <input
                    disabled={disabled}
                    style={{ width: "22%" }}
                    min={1}
                    value={input.kyukeiShuryoJikan1}
                    onChange={e =>
                      onChangeInput("kyukeiShuryoJikan1", e.target.value)
                    }
                    type="time"
                  />
                </div>
              ) : (
                <>
                  <span style={{ width: "10%" }}>休憩(分)</span>
                  <input
                    disabled={disabled}
                    style={{ width: "14%" }}
                    min={1}
                    value={input.kyukeiMinutes}
                    onChange={e =>
                      onChangeInput("kyukeiMinutes", e.target.value)
                    }
                    type="number"
                  />
                </>
              )}
            </div>
            <Divider />
            <InputSet
              label="人数"
              disabled={disabled}
              type="number"
              min={1}
              value={input.boshuNinzu}
              onChange={e => onChangeInput("boshuNinzu", e.target.value)}
            />
            <Divider />
            <InputSet
              label="時給"
              disabled={disabled}
              min={1}
              value={input.kijunTanka}
              onChange={e => onChangeInput("kijunTanka", e.target.value)}
              type="number"
            />
            <Divider />
            <InputSet
              label="案件名"
              disabled={disabled}
              value={input.title}
              onChange={e => onChangeInput("title", e.target.value)}
            />
            <DashButton
              onClick={registerOrder}
              disabled={isSubmitButtonDisabled()}
            />
            <div style={{ marginTop: "3rem" }}>
              <button onClick={() => signOut()}>ログアウト</button>
            </div>
          </>
        )
      ) : (
        <div id="show-auth"></div>
      )}
    </div>
  );
};
