import React from "react";
import Animation from "./Animation";
import { STATUS } from "../constants";
import check from "../animations/check.json";
import error from "../animations/error.json";
import loading from "../animations/loading.json";

const animationStyle = {
  width: 300,
  height: 300,
  position: "absolute"
};

export default ({ status, onComplete }) => (
  <>
    {status === STATUS.LOADING && (
      <Animation
        style={animationStyle}
        immediate
        data={loading}
        onComplete={() => {}}
      />
    )}
    {status === STATUS.SUCCESS && (
      <Animation
        style={animationStyle}
        immediate
        data={check}
        onComplete={() => onComplete(STATUS.SUCCESS)}
      />
    )}
    {status === STATUS.FAIL && (
      <Animation
        style={animationStyle}
        immediate
        data={error}
        onComplete={() => onComplete(STATUS.FAIL)}
      />
    )}
  </>
);
